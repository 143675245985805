import React from "react"

const Map = () => (
  <iframe
    frameBorder="0"
    style={{ width: "100%", height: "45vh", marginBottom: 0 }}
    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAgTIHrYRBYi5K8FW8RIN5HEi8oCRRSIRE&q=serwis+olejowy,Proszowice"
    allowFullScreen
  />
)

export default Map
