import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { ServiceBox } from "../components/ServiceBox"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Map from "../components/Map"
import Ac from "../images/ac.jpg"

import { Grid } from "../components/Grid"
import {
  faMapMarkedAlt,
  faPhoneAlt,
  faClock,
} from "@fortawesome/free-solid-svg-icons"
import { Icon } from "../components/Icon"
const StyledWrapper = styled.div`
  justify-content: center;
  display: grid;
  margin: 200px 0;
  margin-bottom: 150px;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 5px 8px 19px -5px rgba(0, 0, 0, 0.7);
  @media (max-width: 800px) {
    padding: 0;
  }
`
const ContactInfoWrapper = styled.div`
  background-color: ${({ theme }) => theme.darkBlue};
  width: 100%;
  height: 110px;

  @media (max-width: 800px) {
    height: 100%;
    padding: 20px;
  }
`
const InnerWrapper = styled.ul`
  margin: 0 auto;
  max-width: 1200px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  grid-auto-flow: dense;
  height: 100%;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    padding: 10px 0px;
    font-size: 15px;
    color: white;
    line-height: 20px;
    text-transform: uppercase;
    p {
      margin-left: 10px;
    }
    a {
      text-decoration: none;
      color: white;
    }
    &:nth-of-type(2n) {
      border-left: 1px solid;
      border-right: 1px solid;
      border-color: white;
      padding: 0;
      height: 50%;
    }
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 30px;

    li {
      margin-left: 0;
      &:nth-of-type(2n) {
        border: none;
      }
    }
  }
`

const TextBox = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.darkBlue};
  p {
    font-size: 0.9rem;
    font-weight: 300;
    text-align: justify;
    color: #f7f7f7;
  }
  div {
    padding: 5%;
    color: white;
  }
  span {
    font-size: 16px;
    color: #777;
  }

  @media (max-width: 900px) {
    grid-column: 1 / span 2;
    margin: 0;
    height: 100%;
  }
`
const ImgBox = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ img }) => `url(${img})`};
  background-size: cover;
  @media (max-width: 900px) {
    grid-column: 1 / span 2;
    margin: 0;
    width: 100%;
    height: 100%;
  }
`

const StyledSection = styled.section`
  margin: 50px auto;
  max-width: 1200px;
`
const FullWidthSection = styled.section`
  width: 100%;
`
export const query = graphql`
  query {
    about: file(relativePath: { eq: "about.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    car: file(relativePath: { eq: "carMechanic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    filter: file(relativePath: { eq: "filter.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    moto: file(relativePath: { eq: "motorcycle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    oil: file(relativePath: { eq: "oil.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ac: file(relativePath: { eq: "ac.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Mechanik Samochodowy" />
      <StyledSection id="home">
        <Grid>
          <ServiceBox
            text="wymiana oleju"
            col="2"
            row="3"
            img={data.oil.childImageSharp.fluid.src}
            backgroundPosition={[60, 70]}
            navigateTo="/serwis-olejowy"
          />
          <ServiceBox
            backgroundPosition={[50, 47]}
            text="mechanika motocyklowa"
            col="2"
            row="2"
            img={data.moto.childImageSharp.fluid.src}
            navigateTo="/serwis-motocykli"
          />
          <ServiceBox
            backgroundPosition={[90, 30]}
            text="mechanika samochodwa "
            col="2"
            row="2"
            img={data.car.childImageSharp.fluid.src}
            navigateTo="/mechanika-samochodowa"
          />
          <ServiceBox
            text="serwis klimatyzacji"
            col="2"
            row="1"
            img={data.ac.childImageSharp.fluid.src}
            navigateTo="/obsluga-klimatyzacji"
          />
          <ServiceBox
            backgroundPosition={[90, 75]}
            text="Oleje filtry akcesoria"
            col="4"
            row="2"
            img={data.filter.childImageSharp.fluid.src}
            navigateTo="/oleje-filtry-akcesoria"
          />
        </Grid>
      </StyledSection>
      <StyledSection id="o-nas">
        <StyledWrapper>
          <ImgBox img={data.about.childImageSharp.fluid.src} />
          <TextBox>
            <div>
              <span>Działamy od 1998r</span>
              <h2>Serwis Sagan</h2>

              <p>
                Rozpoczęliśmy działalność w 1998 roku, oferujemy usługi z
                zakresu serwisu olejowego oraz napraw mechanicznych pojazdów
                samochodowych i motocykli.
              </p>

              <p>
                Przez lata zdobywaliśmy doświadczenie w dziedzinie napraw i
                obsługi wszystkich marek pojazdów. Na bieżąco wdrażamy
                nowoczesne technologie, poszerzamy naszą wiedzę poprzez udział w
                szkoleniach i konferencjach branżowych. Posiadamy sprzęt do
                diagnozy i obsługi najnowszych modeli samochodów. Szanujemy
                Państwa czas, dlatego jesteśmy punktualni, uczciwie i rzetelnie
                podchodzimy do każdego klienta.{" "}
              </p>
              <p style={{ textAlign: "center", fontWeight: "500" }}>
                ZAPRASZAMY DO KORZYSTANIA Z NASZYCH USŁUG !!!
              </p>
            </div>
          </TextBox>
        </StyledWrapper>
      </StyledSection>
      <FullWidthSection id="kontakt">
        <ContactInfoWrapper>
          <InnerWrapper>
            <li>
              <Icon icon={faMapMarkedAlt} size="2x" />
              <p>
                3 Maja 111A
                <br /> 32-100 Proszowice
              </p>
            </li>
            <li>
              <Icon icon={faPhoneAlt} size="2x" />
              <p>
                <a href="tel:+123863336">12 386 33 36 </a>
              </p>
            </li>
            <li>
              <Icon icon={faClock} size="2x" />
              <p>
                Pon — Pt: 8:00 — 17:00
                <br />
                Sobota : &nbsp; 8:00 — 13:00
              </p>
            </li>
          </InnerWrapper>
        </ContactInfoWrapper>
        <Map />
      </FullWidthSection>
    </Layout>
  )
}

export default IndexPage
