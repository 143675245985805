import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  height: 100%;
`
const InnerWrapper = styled.div`
  background: url(${props => props.img}) rgba(0, 0, 0, 0.3);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${({ backgroundPosition }) =>
    `${backgroundPosition[0]}%  ${backgroundPosition[1]}% `};
  background-blend-mode: multiply;
  transition: background-color 0.2s, transform 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  width: 100%;
  height: 100%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.6);
    transform: scale(1.1) rotate(0.02deg);
  }
`
const StyledWrapper = styled.div`
  grid-column: span ${props => props.col};
  grid-row: span ${props => props.row};
  overflow: hidden;
  position: relative;

  span {
    letter-spacing: 2.5px;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    bottom: 25px;
    left: 10%;
    overflow: hidden;
  }

  @media (max-width: 800px) {
    grid-row: span 1;
    grid-column: span 1;
  }
`

export const ServiceBox = ({
  col,
  row,
  img,
  navigateTo,
  text,
  backgroundPosition,
}) => {
  return (
    <StyledWrapper col={col} row={row}>
      <InnerWrapper img={img} backgroundPosition={backgroundPosition}>
        <StyledLink to={navigateTo}>
          <span>{text}</span>
        </StyledLink>
      </InnerWrapper>
    </StyledWrapper>
  )
}

InnerWrapper.defaultProps = {
  backgroundPosition: [50, 50],
}
