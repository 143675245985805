import styled from "styled-components"

export const Grid = styled.div`
  justify-content: center;
  margin: 100px auto;
  align-self: center;
  max-width: 1000px;
  display: grid;
  font-family: "Montserrat";
  grid-template-columns: repeat(4, minmax(120px, 300px));
  grid-template-rows: repeat(6, minmax(50px, 100px));
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.1) 0px 4px 6px -2px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 200px);
  }
  @media (min-width: 1600px) {
    max-width: 1200px;
  }
`
